export default function checkCanTeaserStart(){
    let canStart = false
    if (window.setkaIsInitTime) {
      const date = new Date() 
      canStart =  date.getTime() - window.setkaIsInitTime > 3 * 1000
    } else { 
      window.setkaIsInitTime = new Date().getTime()
      canStart= true
    }
    return canStart
  }