<script>
  import { onMount } from "svelte";
  import getIPAddress from './helpers/API/getIPAddress'
  import getScreenSize from './helpers/getScreenSize'
  import { getRelativeClickCoordinates } from './helpers/getRelativeClickCoordinates'
  import sendClickStatData from './helpers/sendClickStatData'
  export let data;
  export let index;
  export let isMobile;
  export let slider;
  export let matrix;
  export let appOptions;

  let nodeElem;
  let clickPositionXData;
  let clickPositionYData;

  let isSendFlag = false;
  let { spareTeasers, nofollow, bid, mode, type } = appOptions;
  const domain = window.location.hostname;

  const getScreenSizeData = getScreenSize();

  const handleGetRelativeClickCoordinates = (event) => {
    let sliderElement = slider instanceof Element ? slider : matrix;

    const { clickPositionX, clickPositionY } = getRelativeClickCoordinates(event, sliderElement);
    
    clickPositionXData = clickPositionX
    clickPositionYData = clickPositionY
  };

  async function sendClickStat() {
    let ipAddress
    let sliderElement = slider instanceof Element ? slider : matrix;

    await getIPAddress('https://api.ipify.org/').then((resp) => { ipAddress = resp });

    const { teaserClickStatUrl } = 
      sendClickStatData(
        ipAddress, isMobile, sliderElement, 
        getScreenSizeData, nodeElem, data, 
        bid, clickPositionXData, clickPositionYData
      );

    await fetch(teaserClickStatUrl)
    .then(() => {
      if ((type == "matrix_1x3_stripe" && mode !== "no-image")) {
        dispatch("changeSlide", {
          id: data.id,
        })
      }
    })
    .then(() => {
      if (spareTeasers.length) {
        data = spareTeasers.pop();
        isSendFlag = false;
        data.isVisible = true;
      }
    });
  }

  function sendRenderStat() {
    if (data.isVisible && !isSendFlag) {
      let viewed = window.localStorage.getItem('setka-media-store');
      if (viewed && viewed.length) {
        viewed = viewed.split(",");
        viewed.push(data.id);
        viewed = viewed.join(",");
        window.localStorage.setItem('setka-media-store', viewed);
      } else {
        window.localStorage.setItem('setka-media-store', data.id);
      }
      fetch(
        `https://setka.media/external/teaser-view?tid=${data.id}&bid=${bid}`
      );
      isSendFlag = true;
    }
  }
  $: data.isVisible, sendRenderStat();

  let linkUrl = `${data.url}?bid=${bid}&utm_source=${domain}`;
  onMount(() => {    
    const parent = nodeElem.closest("[data-adfox]");

    if (parent) {
      const dataAdfox = parent.dataset.adfox.replace(
        "%setka.url%",
        encodeURIComponent(linkUrl)
      );
      linkUrl = dataAdfox;
    }
  });

  let linkAttributes = {
    rel: nofollow ? "nofollow" : '',
    target: "_blank"
  }

  $: data.url, linkUrl = `${data.url}?bid=${bid}&utm_source=${domain}`
</script>

<div class="link-wrapper">
  <span class="link-number">{index + 1}</span>
  <a
    data-teaser-pos={index + 1}
    bind:this={nodeElem}
    class={mode != "no-image" ? "stripe" : "stripe stripe--no-image"}
    href={linkUrl}
    on:click={handleGetRelativeClickCoordinates}
    on:click={sendClickStat}
    target={linkAttributes.target}
    rel={linkAttributes.rel}
  >
    {#if mode != "no-image"}
      <img
        class="stripe__image"
        src={data.image}
        alt={data.text}
        loading="lazy"
      />
    {/if}
    <div class="stripe__text custon__text">{data.text}</div>
  </a>
</div>

<style>
  .link-wrapper {
    display: flex;
    align-items: flex-start;
  }
  .link-number {
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #ededed;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
</style>
