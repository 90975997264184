export default async function getTeasersInterface(url, variavles) {
    const { viewed, bid } = variavles
    return fetch(url, {
        method: "post",
        body: JSON.stringify({ bid, viewed: viewed.length > 0 ? viewed.split(',') : [] }),
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }).then((data)=>{
        return data.json()
    })

    // var url_with_params = url + '&' + new URLSearchParams({
    //     bid: bid,
    //     viewed: [],
    // });
    // console.log(url_with_params);
    // return fetch(url_with_params, {
    //     method: 'GET',
    //     credentials: 'include',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/json;charset=utf-8'
    //     }
    // }).then(response => console.log(response.json()))
    // // .then((data)=>{
    // //     return data.json()
    // // })
}