import extractDomain from './extractDomain'
import getDate from './getDate'


export default function sendClickStatData(ipAddress, isMobile, sliderElement, getScreenSizeData, nodeElem, slide, bid, clickPositionXData, clickPositionYData) {
//   let sliderElement = slider instanceof Element ? slider : matrix;
  const hostName = extractDomain(window.location.href);
  const base64Date = btoa(getDate());
  const base64IP = btoa(ipAddress);
  const getIsMobile = () => (isMobile ? "m" : "d");
  const sliderWidth = sliderElement.scrollWidth;
  const sliderHeight = sliderElement.clientHeight;
  const screenWidth = getScreenSizeData.screenWidth;
  const screenHeight = getScreenSizeData.screenHeight;
  const teaserPos = nodeElem.dataset.teaserPos;

  return {
    teaserClickStatUrl: `https://setka.media/external/teaser-click?tid=${slide.id}&bid=${bid}&h=${hostName}&t=${base64Date}&i=${base64IP}&ua=${getIsMobile()}&resb=${sliderWidth}x${sliderHeight}&ress=${screenWidth}x${screenHeight}&pos=${clickPositionXData}x${clickPositionYData}&num=${teaserPos}`
  };
}
