export default function getDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getUTCHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const offsetHours = Math.floor(Math.abs(now.getTimezoneOffset()) / 60);

    return `${year}-${month}-${day} ${hour}:${minute} GMT+${offsetHours.toString()}`;
}