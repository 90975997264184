import Setka from "./main";
import checkCanTeaserStart from './helpers/checkCanTeaserStart.js'
import deleteDublicate from "./helpers/deleteDublicate";
import getTeasersTypeInterface from "./helpers/API/getTeasersTyteInterface";
import createCallCounter from "./helpers/createCallCounter.js";
import crc32 from "./helpers/crc32.js";
import removeQueryString from "./helpers/removeQueryString.js";
import throttle from "./helpers/throttle.js"

function CreateSetkaMedia() {
    let canStart = checkCanTeaserStart()
    if (!canStart) return
    createCallCounter()

    let setkaList = document.querySelectorAll(".js-setka-media")
    if (setkaList.length) {
        const clearBidList = deleteDublicate(setkaList)
        clearBidList.forEach( async (pos, bid) => {

            const params = await ( await getTeasersTypeInterface('https://setka.media/external/rotator-view?bid=' + bid + `&crc=${crc32(removeQueryString(window.location.href))}`))
            Setka({
                bid: bid,
                blockid: `.js-setka-media[data-bid="${bid}"]`,
                params,
                pos
            });

            let selectorsQuantity = setkaList.length;
            let scrollListener = throttle(function() {
                const setkaNodesList = document.querySelectorAll('.js-setka-media')
                if (selectorsQuantity !== setkaNodesList.length) {
            
                    selectorsQuantity = setkaNodesList.length
                        Setka({
                            bid: bid,
                            blockid: `.js-setka-media[data-bid="${bid}"]`,
                            params,
                            pos
                        });
                }
            }, 1000)

            window.addEventListener('scroll', scrollListener)

            if (sessionStorage.getItem('createCallCount') > 1) {
                window.removeEventListener('scroll', scrollListener);
            }

            window.onload = function() {
                sessionStorage.removeItem('createCallCount')
            }
        })

    }

}
CreateSetkaMedia()
