<script>
  import { onMount } from "svelte";
  import ClassicSlide from "./ClassicSlide.svelte";
  import HeaderComponent from "./HeaderComponent.svelte";
  import checkVisible from "./helpers/checkVisible";
  import Stripe from "./Stripe.svelte";

  export let slider
  export let matrix
  export let appOptions

  let isVisible = false;
  let isMobile = false;
  let isSendStat = false;
  let minWidth = 900; // 4x2 matrix_5x2
  let { signature_show, nofollow, spareTeasers, mobileMode, bid, mode, tiserList, type } = appOptions;

  if (type === "matrix_2x2" || type === "matrix_1x3") {
    minWidth = 500;
  }
  if (type === "matrix_3x1" || type === "matrix_3x2") {
    minWidth = 700;
  }
  if (type === "matrix_4x1" || type === "matrix_4x2") {
    minWidth = 800;
  }

  onMount(function init() {
    isVisible = checkVisible(matrix);
    isMobile = matrix.parentElement.clientWidth <= minWidth;
    if (isMobile && mobileMode === "stripe") {
      type = "matrix_1x3_stripe";
    }
    if (isMobile && mobileMode === "flex") {
      type = "matrix_1x3";
      mode = "overlay_flex";
    }
    if (isMobile && mobileMode === "2x3") {
      type = "matrix_2x2";
      mode = "classic";
    }
    if (isMobile && mobileMode === "1x3") {
      type = "matrix_1x3";
    }
    if (isMobile && mobileMode === "1x3_top") {
      type = "matrix_1x3_top";
      mobileMode = "flex";
    }
  });

  function scrollVertical() {
    if (checkVisible(matrix) && isVisible !== true) {
      isVisible = true;
    }
  }

  function changeVisibleSlide() {
    if (isVisible) {
      if (!isSendStat) {
        fetch(`https://setka.media/external/block-view?bid=${bid}`);
        isSendStat = true;
      }
      tiserList.forEach((element) => {
        element.isVisible = true;
      });
      tiserList = tiserList;
    }
  }

  function changeSlide(data){
    const id = data.detail.id
    const index = tiserList.findIndex(item=>{
      return item.id===id
    })
    
    if(spareTeasers.length){
      const newTeaser = spareTeasers.pop()
      newTeaser.isVisible = true

      tiserList[index] = newTeaser
    }
  }

  $: isVisible, changeVisibleSlide();
</script>

<svelte:window on:scroll={scrollVertical} />
<div class={"matrix"} bind:this={matrix}>
  {#if signature_show==true}
  <HeaderComponent/>
  {/if}
  
  <div class={"matrix__list " + type}>
    {#each tiserList as slide, index}
      {#if type == "matrix_1x3_top"}
        {#if index == 0}
          <ClassicSlide {index} {slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>
        {:else}
          <Stripe {index} data={slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>
        {/if}
      {:else if type == "matrix_1x3_stripe" && mode !== "no-image"}
        <Stripe {index} data={slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>
      {:else if mode == "no-image"}
        <Stripe {index} data={slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>
      {:else}
        <ClassicSlide {index} {slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>      
      {/if}
    {/each}
  </div>
</div>

<style>
 
</style>
