import App from './App.svelte';
import getTeasersInterface from './helpers/API/getTeasersInterface';
import { props } from './helpers/prepareForCreateApp'

function Setka(options) {

  const url = new URL(`https://setka.media/rotator`)
  const params = new URLSearchParams();
  options.pos !== 0 ? params.set('pos', options.pos) : params.set('pos', 0)

  const viewed = window.localStorage?.getItem('setka-media-store') ?? []

  getTeasersInterface(url + '?' + params.toString(), { viewed, bid: options.bid })
    .then(async ({ teasers, bench, count = 10 }) => {
      if (!teasers.length) return;
      teasers = teasers.filter(item => item.text !== undefined)
      let spareTeasers = bench
      teasers.forEach(element => {
        element.isVisible = false
      })

      const selectorsList = document.querySelectorAll(options.blockid)
      const numberOfInstans = selectorsList.length;
      while (teasers.length < count * numberOfInstans) {
        const temp = [...teasers]
        teasers = [...teasers, ...temp]
      }

      selectorsList.forEach((selector, index) => {
        if (selector.innerHTML === '') {

          if (options.params.css && index == 0) {
            const isCustomStyle = document.querySelector("[data-setka=media]")
    
            if (isCustomStyle) {
                isCustomStyle.innerHTML += options.params.css
            } else {
                const style = document.createElement('style')
                style.dataset.setka = 'media'
                style.innerHTML = options.params.css
                selector.before(style)
            }
          }

          const teaserList = teasers.slice(index * count, index * count + count)

          const propsObject = props(options, teaserList, spareTeasers);

          new App({
            target: selector,
            props: propsObject
          });
        } 
      })
    }).catch(error => {
      console.error("Setka is broken")
    })
}

export default Setka;

