export function getRelativeClickCoordinates(event, slider) {
  const sliderTopPos = Math.round(
    slider.getBoundingClientRect().top + window.scrollY
  );
  const sliderLeftPos = Math.round(
    slider.getBoundingClientRect().left + window.scrollX
  );

  const relativeX = event.pageX - sliderLeftPos + slider.scrollLeft;
  const relativeY = event.pageY - sliderTopPos;

  return {
    clickPositionX: Math.round(relativeX),
    clickPositionY: Math.round(relativeY),
  };
}
