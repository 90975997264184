export const props = (options, teaserList, spareTeasers) => ({
    signature_show: options.params.signature_show ?? true,
    nofollow: options.params.nofollow ?? false,
    type: options.params.type,
    mode: options.params.mod,
    mobileMode: options.params.mobileMode,
    tiserList: teaserList,
    spareTeasers: spareTeasers,
    bid: options.bid,
    slideWidth: options.params.slideWidth ?? 300,
    slideHeight: options.params.slideHeight ?? 300
});