export default function throttle(func, limit) {
    let lastCall = 0;
  
    return function (...args) {
      const now = new Date().getTime();
  
      if (now - lastCall >= limit) {
        lastCall = now;
        func.apply(this, args);
      }
    };
}