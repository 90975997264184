<script>
  import "../public/forBundle.css";
  export let tiserList;
  export let spareTeasers;
  export let type;
  export let mode;
  export let bid;
  export let slideWidth;
  export let slideHeight;
  export let mobileMode = "stripe";
  export let signature_show;
  export let nofollow;
  import ClassicSlider from "./ClassicSlider.svelte";
  import Matrix from "./Matrix.svelte";
  import { onMount } from "svelte";

  let isMatrix = false;
  let isSlider = false;
  let slider;
  let matrix
  let appOptions = { signature_show, nofollow, spareTeasers, mobileMode, bid, mode, tiserList, type, size: {slideWidth, slideHeight} };

  onMount(() => {
    isMatrix = type.indexOf("matrix") !== -1;
    isSlider = type.indexOf("slider") !== -1;
  });
</script>

<div class="main-setka">
  {#if isSlider === true}
    <ClassicSlider
      {slider}
      {matrix}
      {appOptions}
    />
  {/if}

  {#if isMatrix === true}
    <Matrix
      {slider}
      {matrix}
      {appOptions}
    />
  {/if}
</div>

<style>
</style>
