<script>
import { onMount, onDestroy } from "svelte";
import throttle from "./helpers/throttle.js"


export let slider
export let matrix
export let appOptions

import ClassicSlide from "./ClassicSlide.svelte";
import HeaderComponent from "./HeaderComponent.svelte";
import checkVisible from "./helpers/checkVisible"


  let isLeftSide = true;
  let isRightSide = false;
  let windowWidth
  let ostatok
  let count
  let isSendStat = false
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
  
  let isVisible = false
  let previousClientX;
  
  let sliderGap
  let isMirrorSliderReady = false
  let hasScrolled = false;
  let isIntervalStarted = false
  let isSliderGone = false
  let visibleInterval
  let { signature_show, nofollow, spareTeasers, bid, mode, tiserList, type, size } = appOptions;

  const checkScroll  = function(){
    const newCount = (slider.scrollLeft + ostatok) / (size.slideWidth + 16)
    const totalCount = Math.trunc( count + newCount)
    for (let index = count -1; index < totalCount; index++) {
      tiserList[index].isVisible = true
    }

    // Проверки границ
      if (slider.scrollLeft === 0) {        
        isLeftSide = true   
      } else if (slider.scrollWidth - slider.scrollLeft - 5 <= slider.clientWidth) { //5 пикселей это костыль на мобильных появляется погрешность
        isLeftSide = false
        isRightSide = true

        //Достигли правой стороны значит все тизеры просмотремы
        for (let index = count -1; index < tiserList.length; index++) {
            tiserList[index].isVisible = true
        }

      } else {
        isLeftSide = false
        isRightSide = false
      }
    }

  const scrollRight = ()=>{    
    slider.scrollBy(size.slideWidth, 0)
  }

  const scrollLeft = ()=>{   
    slider.scrollBy(-size.slideWidth, 0)
  }


  onMount(()=>{
    const sliderMirrorElementStyles = window.getComputedStyle(slider)
    sliderGap = parseInt(sliderMirrorElementStyles.gap.replace("px", ""))

    isVisible = checkVisible(slider)  
    windowWidth = slider.offsetWidth      

    if(windowWidth > tiserList.length * size.slideWidth){
      isRightSide = true
    }

    if (mode==='long'){
      size.slideWidth = slider.clientWidth
    }
  })

  onDestroy(() => {
    window.removeEventListener('scroll', scrollListeners)
    window.removeEventListener('mousemove', scrollListeners)
  })

  function scrollVertical(){
    if(checkVisible(slider) && isVisible !==true){
      isVisible = true
    }    
  }

  function mirrorSliderOverhang() {
    document.querySelectorAll(".mirror__item").forEach((mirrorSlide) => {
        mirrorSlide.style.transform = 'translateX(0)';
        isMirrorSliderReady = true
    });
  }

  let scrollListeners = throttle(function() {
    function scrolledByTwoSlides() {
      slider.style.scrollBehavior = 'smooth'
      slider.scrollBy((size.slideWidth + sliderGap) * 2, 0)
      slider.style.scrollBehavior = 'unset'
      hasScrolled = true;
    }

    if (!isMirrorSliderScrolled) {
      if (isSliderGone && isMirrorSliderReady && checkVisible(slider) && !hasScrolled) {
          setTimeout(() => {
            scrolledByTwoSlides()
          }, 1000)
      }

      if (checkVisible(slider) && !hasScrolled && !isIntervalStarted && !isSliderGone && isMirrorSliderReady) {
        isIntervalStarted = true
        visibleInterval = setInterval(() => {
          if (checkVisible(slider) && !hasScrolled && isMirrorSliderReady) {
            scrolledByTwoSlides()
            clearInterval(visibleInterval)
          } else { 
            clearInterval(visibleInterval)
          }
        }, 10 * 1000);
      }

      if (isVisible !== checkVisible(slider)) {
        isSliderGone = true
      }
    } else {
        window.removeEventListener('scroll', scrollListeners)
        window.removeEventListener('mousemove', scrollListeners)
        clearInterval(visibleInterval)
    }
  }, 1200);

  if (mode === 'mirror') {
    window.addEventListener('scroll', scrollListeners)
    window.addEventListener('mousemove', scrollListeners)
  }

  $: {
    if (hasScrolled) 
      window.removeEventListener('scroll', scrollListeners), 
      window.removeEventListener('mousemove', scrollListeners)
  }

  function changeVisibleSlide(){   
    if(isVisible){
      if (!isSendStat){
        fetch(`https://setka.media/external/block-view?bid=${bid}`)
         setTimeout(mirrorSliderOverhang, 450)
         isSendStat = true;
      }
      count = Math.trunc(windowWidth / (size.slideWidth + 16)) //целая часть видимых 
      ostatok = windowWidth % (size.slideWidth + 16)      // невидиммые 
      
      for (let index = 0; index < count; index++) {
        if(index < tiserList.length){
          tiserList[index].isVisible = true;
        }
      }
    }
  }

  function changeSlide(data){
    const id = data.detail.id
    const index = tiserList.findIndex(item=>{
      return item.id===id
    })
    
    if(spareTeasers.length){
      const newTeaser = spareTeasers.pop()
      newTeaser.isVisible = true

      tiserList[index] = newTeaser
    }
  }

  $: isVisible, changeVisibleSlide()

  let isMirrorSliderScrolled = false

  function handleMouseMove(event) {
    if (mode === 'mirror') {
      isMirrorSliderScrolled = true
      let mouseMoveTimer;

      function rotateItems(deg, items) {
        items.forEach((item) => {
          item.style.transition = "transform 0.2s ease-in-out";
          item.style.transform = `perspective(40px) rotateY(${deg}deg)`;
        });
      }

      (function sliderScrollSystem () {
        clearTimeout(mouseMoveTimer);

        const clientX = event.pageX - slider.getBoundingClientRect().left;
        const diffX = clientX - previousClientX;
        const sliderWidth = slider.clientWidth;
        const scrollDistance =
          (clientX / sliderWidth) * (slider.scrollWidth - sliderWidth);
        const sliderItems = document.querySelectorAll(".mirror__item");

        rotateItems(diffX > 0 ? -1 : 1, sliderItems);

        clientX <= 20
          ? (slider.scrollLeft = 0)
          : (slider.scrollLeft = scrollDistance);

        previousClientX = event.pageX - slider.getBoundingClientRect().left;

        mouseMoveTimer = setTimeout(() => rotateItems(0, sliderItems), 300);
    })();
  }
}
</script>

<svelte:window on:scroll={throttle(scrollVertical, 300)}/>
<div class="classic-slider">
  {#if signature_show==true}
    <HeaderComponent/>
  {/if}
  <div class="classic-slider__interface">
    
    {#if mode !== 'mirror'}
      {#if isLeftSide==false && !isMobile}
      <div class="classic-slider__arrow classic-slider__arrow_left" on:click={scrollLeft}>
        <svg width="25" height="25">
          <polygon points="0,12 25,25 25,0"
            fill="#888" stroke="#666" stroke-width="1"
          />
        </svg>
      </div>
      {/if}

      {#if isRightSide==false && !isMobile}
      <div class="classic-slider__arrow classic-slider__arrow_right" on:click={scrollRight}>
        <svg width="25" height="25">
          <polygon points="0,25 25,12 0,0"
            fill="#888" stroke="#666" stroke-width="1"
          />
        </svg>
      </div>  
      {/if} 
    {/if}
    <div class="{type} {mode === 'mirror' ? 'slider_mirror' : ''}" bind:this={slider} on:scroll={checkScroll} on:mousemove={handleMouseMove}>
      {#each tiserList as slide, index}
      <div class="{mode === 'mirror' ? 'mirror__item' : 'slider__item'}">
        <ClassicSlide {index} {slide} {slider} {matrix} {isMobile} {appOptions} on:changeSlide={changeSlide}/>
      </div>  
      {/each}    
    </div>  
  </div>
  
  
    
  
</div>

<style>
  
</style>