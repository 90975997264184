<script>
  import { createEventDispatcher, onMount } from "svelte";
  import getIPAddress from './helpers/API/getIPAddress'
  import getScreenSize from './helpers/getScreenSize'
  import { getRelativeClickCoordinates } from './helpers/getRelativeClickCoordinates'
  import sendClickStatData from './helpers/sendClickStatData'
  export let slider;
  export let matrix;
  export let slide;
  export let appOptions;
  export let isMobile;
  export let index;
  let nodeElem;
  let clickPositionXData;
  let clickPositionYData;
  let ipAddress
  let { nofollow, mobileMode, bid, mode, size } = appOptions;
  const dispatch = createEventDispatcher();
  if (mode == "overlay") {
    mode = "overlay_custom";
  }

  let isSendFlag = false;
  const domain = window.location.hostname;

  function sendRenderStat() {
    if (slide.isVisible && !isSendFlag) {
      let viewed = window.localStorage.getItem('setka-media-store');

      if (viewed && viewed.length) {
        viewed = viewed.split(",");
        viewed.push(slide.id);
        viewed = viewed.join(",");
        window.localStorage.setItem('setka-media-store', viewed);
      } else {
        window.localStorage.setItem('setka-media-store', slide.id);
      }

      fetch(
        `https://setka.media/external/teaser-view?tid=${slide.id}&bid=${bid}`
      );
      isSendFlag = true;
    }
  }
  $: slide.isVisible, sendRenderStat();

  const getScreenSizeData = getScreenSize();

  const handleGetRelativeClickCoordinates = (event) => {
    let sliderElement = slider instanceof Element ? slider : matrix;

    const { clickPositionX, clickPositionY } = getRelativeClickCoordinates(event, sliderElement);
    
    clickPositionXData = clickPositionX
    clickPositionYData = clickPositionY
  };

  async function sendClickStat() {
    let ipAddress
    let sliderElement = slider instanceof Element ? slider : matrix;

    await getIPAddress('https://api.ipify.org/').then((resp) => { ipAddress = resp });

    const { teaserClickStatUrl } = 
      sendClickStatData(
        ipAddress, isMobile, sliderElement, 
        getScreenSizeData, nodeElem, slide, 
        bid, clickPositionXData, clickPositionYData
      );

    await fetch(teaserClickStatUrl)
      .then(() => {
        dispatch("changeSlide", {
          id: slide.id,
        });
      });
  }

  let styleImage;
  let styleLink;
  const flexString = `
        position:absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width:100%;
        height:100%;
        `;

  $: if (!isMobile) {
    if (mode === "overlay_top") {
      styleImage = `width: 100%; height: ${size.slideHeight}px;`;
    } else if (mode === "overlay_flex") {
      styleLink = `width: 100%; padding-top:75%;`;
      styleImage = flexString;
    } else if (mode === "classic_flex") {
      styleLink = `width: 100%;`;
      styleImage = flexString;
    } else {
      styleImage = `width: ${size.slideWidth}px; height: ${size.slideHeight}px;`;
      styleLink = `width: ${size.slideWidth}px;`;
    }
  } else {
    if (mobileMode) {
      if (mobileMode === "flex" || mobileMode === "2x3") {
        styleImage = flexString;
        styleLink = "width:100%";
      } else if (mode === "overlay_flex") {
        styleLink = "width:100%;";
        styleImage = flexString;
      } else {
        styleImage = `width: ${size.slideWidth}px; height: ${size.slideHeight}px;`;
        styleLink = `width: ${size.slideWidth}px;`;
      }
    } else {
      styleImage = `width: ${size.slideWidth}px; height: ${size.slideHeight}px;`;
      styleLink = `width: ${size.slideWidth}px;`;
    }
  }

  let linkUrl = `${slide.url}?bid=${bid}&utm_source=${domain}`;
  onMount(() => {
    const parent = nodeElem.closest("[data-adfox]");
    if (parent) {
      const dataAdfox = parent.dataset.adfox.replace(
        "%setka.url%",
        encodeURIComponent(linkUrl)
      );
      linkUrl = dataAdfox;
    }
  });

  let linkAttributes = {
    rel: nofollow ? "nofollow" : undefined,
    target: "_blank"
  }

  $: slide.url, linkUrl = `${slide.url}?bid=${bid}&utm_source=${domain}`
</script>

<a
  data-teaser-pos={index + 1}
  bind:this={nodeElem}
  class="slide"
  style={styleLink}
  href={linkUrl}
  on:click={handleGetRelativeClickCoordinates}
  on:click={sendClickStat}
  target={linkAttributes.target}
  rel={linkAttributes.rel}
>
  {#if isMobile && (mobileMode === "flex" || mobileMode === "2x3" || (mobileMode === "1x3" && mode !== 'classic_flex') || mobileMode === "1x3_top")}
    <div class="slide__wrapper">
      <img
        class="slide__image"
        style={styleImage}
        src={slide.image}
        alt={slide.text}
        loading="lazy"
      />
    </div>
  {:else if isMobile && mode === 'classic_flex' && mobileMode === "1x3"}
    <div class="slide__wrapper_1x3">
      <img
        class="slide__image"
        style={styleImage}
        src={slide.image}
        alt={slide.text}
        loading="lazy"
      />
    </div>
  {:else if mode === "classic_flex"}
    <div class="slide__wrapper_desc">
      <img
        class="slide__image"
        style={`${styleImage}border-radius: 10px 10px 0px 0px;`}
        src={slide.image}
        alt={slide.text}
        loading="lazy"
      />
    </div>
  {:else}
    <img
      class={mode === 'mirror' ? 'slide__image_mirror' : 'slide__image'}
      style={styleImage}
      src={slide.image}
      alt={slide.text}
      loading="lazy"
    />
    {#if mode === 'mirror'}
      <div class="mirror-image_blue"></div>
      <div class="mirror-image_white"></div>
    {/if}
  {/if}

  {#if mode !== "long"}
    <span class={mode}>
      {slide.text}
    </span>
  {/if}
</a>

<style>

</style>
